import React  from "react";

export function Gallery(props) {

  const galleryItems = props.data.map((galleryItem, index) => {
    return (
        <div key={index} className={`${galleryItem.layout}`}>
          <div className="gallery-item shadow">
            <div className="media-container">
              {" "}
              <img
                  src={galleryItem.media}
                  className="img-responsive media-image"
                  alt={galleryItem.mediaCaption ? galleryItem.mediaCaption : ""}
              />{" "}
              <a
                  href={galleryItem.media}
                  title={galleryItem.mediaCaption ? galleryItem.mediaCaption : ""}
                  data-lightbox-gallery="gallery-overall"
                  aria-haspopup="dialog"
                  className="media-overlay"
              >
                <h4 className="media-text">{""}</h4>
              </a>{" "}
            </div>
          </div>
        </div>
    )
  })

  return (
      <div id="gallery" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Gallery</h2>
            <p>
              A collection of some of my photos so far in Imperial College London
            </p>
          </div>
          <div className="row">
            {galleryItems}
          </div>
        </div>
      </div>
  );
}

export default Gallery;
