// License: https://codyhouse.co/mit

import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import HorizontalTimeline from 'react-horizontal-timeline';

export default class HorizontalTimelineContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            previous: 0,
            showConfigurator: false,

            // timelineConfig
            minEventPadding: 20,
            maxEventPadding: 90,
            linePadding: 80,
            labelWidth: 100,
            fillingMotionStiffness: 150,
            fillingMotionDamping: 25,
            slidingMotionStiffness: 150,
            slidingMotionDamping: 25,
            stylesBackground: 'rgba(255,255,255,.3)',
            stylesForeground: '#5ca9fb',
            stylesOutline: '#dfdfdf',
            isTouchEnabled: true,
            isKeyboardEnabled: true,
            isOpenEnding: true,
            isOpenBeginning: true,
        };
    }

    componentWillMount() {
        console.log(this.props.content)
        this.dates = this.props.content.map((entry) => entry.date);
    }

    componentWillReceiveProps(nextProps, nextContent) {
        this.dates = nextProps.content.map((entry) => entry.date);
    }

    render() {
        const state = this.state;

        const views = this.props.content.map((entry, index) => {
            return (
                <div className='container' key={index}>
                    { entry.component }
                </div>
            );
        });

        return (
            <div>
                <div style={{ width: '60%', height: '100px', margin: '0 auto' }}>
                    <HorizontalTimeline
                        fillingMotion={{ stiffness: state.fillingMotionStiffness, damping: state.fillingMotionDamping }}
                        index={this.state.value}
                        indexClick={(index) => {
                            this.setState({ value: index, previous: this.state.value });
                        }}

                        isKeyboardEnabled={state.isKeyboardEnabled}
                        isTouchEnabled={state.isTouchEnabled}
                        labelWidth={state.labelWidth}
                        linePadding={state.linePadding}
                        maxEventPadding={state.maxEventPadding}
                        minEventPadding={state.minEventPadding}
                        slidingMotion={{ stiffness: state.slidingMotionStiffness, damping: state.slidingMotionDamping }}
                        styles={{
                            background: state.stylesBackground,
                            foreground: state.stylesForeground,
                            outline: state.stylesOutline,
                        }}
                        values={ this.dates }
                        isOpenEnding={state.isOpenEnding}
                        isOpenBeginning={state.isOpenBeginning}
                    />
                </div>
                <div className='text-center'>
                    <SwipeableViews
                        index={this.state.value}
                        onChangeIndex={(value, previous) => {
                            this.setState({ value: value, previous: previous });
                        }}
                        springConfig={{
                            duration: '0.5s',
                            easeFunction: 'cubic-bezier(0.15, 0.25, 0.25, .85)',
                            delay: '0s',
                        }}
                        resistance>
                        {views}
                    </SwipeableViews>
                </div>
            </div>
        );
    }
}