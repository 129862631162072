import React, { Component } from 'react'
import Navigation from './components/Navigation';
import Header from './components/Header';
import Timeline from './components/Timeline';
import About from './components/About';
import CV from './components/CV';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import Projects from './components/Projects';
import Contact from './components/Contact';
import JsonData from './data/data.json';

export class App extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <Header data={JsonData.Header} />
        <About data={JsonData.About} />
        <Timeline data={JsonData.Timeline} />
        <CV/>
        <Projects data={JsonData.Projects} />
        <Testimonials data={JsonData.Testimonials} />
        <Gallery data={JsonData.Gallery}/>
        <Contact data={JsonData.Contact} />
      </div>
    )
  }
}

export default App;
