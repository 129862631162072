import React, { Component } from "react";
import HorizontalTimelineContent from "../widgets/HorizontalTimelineContent";

export class Timeline extends Component {

    componentWillMount() {
        console.log(this.props.data)

        this.data = this.props.data.map((timelineEvent, index) => {
            return ({
                date: timelineEvent.date,
                component: (
                    <div className='container timeline-event text-left' key={index}>
                        <h2 className="timeline-title">{ timelineEvent.name }</h2>
                        <em>{ `- ${ timelineEvent.date2 }` }</em>
                        <div className='row'>
                            <div className='col-xs-12 col-md-7'>
                                <p className='timeline-event-paragraph'>
                                    { timelineEvent.paragraph }
                                </p>
                            </div>
                            <div className='col-xs-12 col-md-5 text-center'>
                                <div className='media-item shadow'>
                                    <img src={ timelineEvent.image } alt="" className='img-responsive media-image'/>
                                </div>
                                <figcaption>{ timelineEvent.caption }</figcaption>
                            </div>
                        </div>
                    </div>
                )
            });
        });
    }

    render() {
        return (
            <div id="timeline">
                <div className="section-title text-center">
                    <h2>Timeline</h2>
                    <p>
                        From when I initially got interested into computing, to my interests now in AWS, Software Engineering and Forward Engineering. Checkout my journey so far in Computer Science!
                    </p>
                </div>
                <HorizontalTimelineContent
                    content={this.data} />
            </div>
        );
  }
}

export default Timeline;
