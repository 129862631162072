import React, { Component } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import emailjs from 'emailjs-com'

const TEMPLATE_ID = "default"
const SERVICE_ID = "service_63mul4r"
const USER_ID = "user_fHkC9Z0T9Dp4L09feBzLG"

export class Contact extends Component {
  constructor(props){
    super(props);
    this.state = {
      // Controls for Inputs - set to TRUE if field is valid
      nameValid: false,
      emailValid: false,
      messageValid: false,

      // Text from inputs
      fromName: '',
      organisation: '',
      email: '',
      message: '',
    };

    this.setName = this.setName.bind(this)
    this.setOrganisation = this.setOrganisation.bind(this)
    this.setEmail = this.setEmail.bind(this)
    this.setMessage = this.setMessage.bind(this)
    this.sendEmail = this.sendEmail.bind(this)

    this.validateName = this.validateName.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.validateMessage = this.validateMessage.bind(this)
    this.validForm = this.validForm.bind(this)

    this.encodeMessage = this.encodeMessage.bind(this)
  }

  // valid inputs will have this set as true
  validateName(bool) {
    this.setState({nameValid: bool});
  }

  validateEmail(bool) {
    this.setState({emailValid: bool});
  }

  validateMessage(bool) {
    this.setState({messageValid: bool});
  }


  setName(event) {
    this.setState({fromName: event.target.value});
    this.validateName(true)
  }

  setOrganisation(event) {
    this.setState({organisation: event.target.value});
  }

  setEmail(event) {
    this.setState({email: event.target.value});
    this.validateEmail(true)
  }

  setMessage(event) {
    this.setState({message: event.target.value});
    this.validateMessage(true)
  }

  validForm() {
    const controlKeys = ["nameValid", "emailValid", "messageValid"]

    const controls = controlKeys.map(key => this.state[key])
    controlKeys.map(key => console.log(`${key} : ${this.state[key]}`))

    // If all true, return true. So check if there are SOME False values. If there are SOME False values, return False
    return !controls.some( value => !value)
  }

  encodeMessage() {
    return (
        {
          fromName: this.state.fromName,
          organisation: this.state.organisation,
          email: this.state.email,
          message: this.state.message,
        }
    )
  }

  sendEmail(event) {
    event.preventDefault();

    if (this.validForm()) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, this.encodeMessage(), USER_ID)
          .then( (response) => {
            console.log('SUCCESS!', response.status, response.text);
            NotificationManager.success("I will get back to you as soon as possible!", "Message successfully sent", 5000)
          }, (err) => {
            console.log(err)
            NotificationManager.error("There was a problem with my EmailJS server, try again or send an email manually" +
                " by clicking the 'Mail' icon in the contact page", "Error, message was not sent", 5000)
          })
    } else {
      console.log("Invalid form")
      NotificationManager.warning("Please make sure that you have filled in the fields correctly", "Invalid Message", 5000)
    }
  }

  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send me an email and I
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate onSubmit={this.sendEmail}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                          onChange={this.setName}
                          onInvalid={() => this.validateName(false)}
                          onInvalidCapture={() => this.validateEmail(false)}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="name"
                          id="email"
                          className="form-control"
                          placeholder="Organisation"
                          onChange={this.setOrganisation}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            placeholder="Email"
                            required="required"
                            onChange={this.setEmail}
                            onInvalid={() => this.validateEmail(false)}
                            onInvalidCapture={() => this.validateEmail(false)}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required="required"
                      onChange={this.setMessage}
                      onInvalid={() => this.validateMessage(false)}
                      onInvalidCapture={() => this.validateEmail(false)}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={`mailto:${this.props.data ? this.props.data.email : "/"}?subject=Recruitment Opportunities`}
                        rel="noopener noreferrer">
                        <i className="fa fa-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.github : "/"} target="_blank"
                         rel="noopener noreferrer">
                        <i className="fa fa-github-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.linkedin : "/"} target="_blank"
                         rel="noopener noreferrer">
                        <i className="fa fa-linkedin-square"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020 Terence Hernandez. Inspired by Issaaf Kattan's{" "}
              <a href="https://github.com/issaafalkattan/React-Landing-Page-Template" rel="nofollow noopener noreferrer" target="_blank">
                React Landing Page Template.
              </a>
              <br/>
              Design by{" "}
              <a href="http://www.templatewire.com" rel="nofollow noopener noreferrer" target="_blank">
                TemplateWire.
              </a>
            </p>
          </div>
        </div>
        <NotificationContainer/>
      </div>
    );
  }
}

export default Contact;
