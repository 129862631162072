import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";

const MediaFormats = Object.freeze({PDF : "pdf", Image : "img", Video: "video", URL: "url"})

export function Projects(props) {

    const [value, setValue] = useState(-1)
    const [previous, setPrevious] = useState(0)

    const generateProject = (index : Number) => {
        setValue(index)
        setPrevious(value)
    }

    const projectList = props.data.map((project, index) => (
        <div key={`${project.name}-${index}`} className="col-md-3 col-sm-6">
            <div className="thumbnail">
                {" "}
            <div onClick={() => generateProject(index)}
                 className={`project-item ${value === index ? "gradient-border" : "inactive-gradient-border shadow"}`}>
                <div className="media-container">
                    <img src={project.img}
                         className="img-responsive media-image"
                         alt={project.briefing}
                    />
                    <a
                        href="#project"
                        title="Project Title"
                        className="media-overlay project-list-media-overlay">
                        <h4 className="media-text project-list-media-text">View Project</h4>
                    </a>
                </div>
            </div>
            <div className="caption">
                <h4>{project.name}</h4>
                <p>{project.briefing}</p>
            </div>
            </div>
        </div>
    ))

    const highlights = (highlight : JSON, projectNum: Number, index: Number) => {
        return (
            <div className={`${highlight.layout ? highlight.layout : ""}`} key={index}>
                <div className="media-item shadow">
                    <div className="media-container">
                        {" "}
                        <img
                            src={highlight.media}
                            className={`img-responsive media-image ${highlight.mediaClass ? highlight.mediaClass : ""}`}
                            alt={highlight.mediaCaption ? highlight.mediaCaption : ""}
                        />{" "}
                        <a
                            href={highlight.media}
                            title={highlight.mediaCaption ? highlight.mediaCaption : ""}
                            data-lightbox-gallery={`highlights-${projectNum}`}
                            className="media-overlay"
                        >
                            <h4 className="media-text">{""}</h4>
                        </a>{" "}
                    </div>
                </div>
            </div>
        )
    }

    const mediaPicker = (href: string, title: string, gallery: string, mediaFormat : string) => {
        if (mediaFormat === MediaFormats.Image) {
            return (
                <a
                    href={href}
                    title={title}
                    data-lightbox-gallery={gallery}
                    className="media-overlay"
                    aria-haspopup="dialog"
                >
                    <i className={`media-icon fa fa-image`}>{""}</i>
                </a>
            )
        }
        if (mediaFormat === MediaFormats.PDF) {
            return (
                <a
                    href={href}
                    title={title}
                    data-lightbox-gallery={gallery}
                    className="media-overlay"
                    aria-haspopup="dialog"
                >
                    <i className={`media-icon far fa-file-pdf`}>{""}</i>
                </a>
            )
        }
        if (mediaFormat === MediaFormats.Video) {
            return (
                <a
                    href={href}
                    title={title}
                    data-lightbox-gallery={gallery}
                    className="media-overlay"
                    aria-haspopup="dialog"
                    data-lightbox-type="video-iframe"
                >
                    <i className={`media-icon fas fa-video`}>{""}</i>
                </a>
            )
        }
        if (mediaFormat === MediaFormats.URL) {
            return (
                <a
                    href={href}
                    title={title}
                    className="media-overlay"
                    aria-haspopup="dialog"
                    target="_blank"
                >
                    <i className={`media-icon fas fa-link`}>{""}</i>
                </a>
            )
        }
    }

    const relatedMedias = (relatedMedia : JSON, projectNum : Number, index : Number) => {
        // Allow other medias like Video or PDF to have an image display
        const displayImage = relatedMedia.display ? relatedMedia.display : relatedMedia.media
        return (
            <div className={`${relatedMedia.layout}`} key={index}>
                <div className="thumbnail">
                    {" "}
                    <div className='media-item'>
                        <div className="media-container related-media-container">
                            <img
                                src={displayImage}
                                className={`img-responsive related-media-image`}
                                alt={"Display image could not be shown"}
                            />{" "}
                            {mediaPicker(relatedMedia.media,
                                relatedMedia.mediaCaption ? relatedMedia.mediaCaption : "",
                                `related-media-${projectNum}`,
                                relatedMedia.type)}
                        </div>
                    </div>
                    <div className="caption">
                        <p>{relatedMedia.mediaCaption ? relatedMedia.mediaCaption : ""}</p>
                    </div>
                </div>
            </div>
        )
    }

    const projects = props.data.map((project, projectNum) => {
        return (
            <div key={`${project.name}-${projectNum}`} className='container' style={{height: "auto"}}>
                <div className="row">
                    <div className="col-xs-12 col-md-7 highlights">
                        {project.highlights.map( (highlight, index) =>
                            highlights(highlight, projectNum, index))}
                    </div>
                    <span></span>
                    <div className="col-xs-12 col-md-5">
                        <div className="description text-left">
                            <h2>{ project.name }</h2>
                            <p>{ project.description ? project.description : <i>{'No description'}</i>}</p>
                            <div className="list-style">
                                <div className="col-lg-5 col-sm-5 col-xs-12">
                                    <h4>Technologies</h4>
                                    {project.technologies ? project.technologies.map( (tech, index) =>
                                        <ul className={tech.bullet} key={index}>
                                            <li>{tech.statement}</li>
                                        </ul>
                                    ) : <i className="default-content">{'No Technologies'}</i>}
                                </div>
                                <div className="col-lg-7 col-sm-7 col-xs-12">
                                    <h4>Extra Info</h4>
                                    {project.extraInfo ? project.extraInfo.map( (info, index) =>
                                        <ul className={info.bullet} key={index}>
                                            <li>{info.statement}</li>
                                        </ul>
                                    ) : <i>{'No Extra Info'}</i>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Related Media</h3>
                        <div className="row justify-content-center">
                            {project.relatedMedias ? project.relatedMedias.map( (relatedMedia, index) =>
                                relatedMedias(relatedMedia, projectNum, index))
                            : <p><i>{'No Related Media'}</i></p>}
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <a
                            className="btn btn-custom btn-lg"
                            href="#projects"
                        >
                            Choose Another Project
                        </a>{" "}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div id="projects" className="text-center">
            <div className="container">
                <div className="col-md-12 section-title">
                    <h2>Projects</h2>
                    <p>
                        Here are some notable projects I've done throughout the years
                    </p>
                </div>
                <div>
                    {projectList}
                </div>
            </div>
            <div id="project">
                <div className="text-center">
                    <SwipeableViews
                        index={value}
                        onChangeIndex={(value, previous) => {
                            setValue(value)
                            setPrevious(previous);
                        }}
                        springConfig={{
                            duration: '0.7s',
                            easeFunction: 'cubic-bezier(0.15, 0.25, 0.25, .85)',
                            delay: '0.7s',
                        }}
                        animateHeight={true}
                    >
                        {projects}
                    </SwipeableViews>
                </div>
            </div>
        </div>
    );
}

export default Projects;
