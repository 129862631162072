import React, { useLayoutEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CVPDF = './files/CV.pdf';

export function CV() {
    const [width, setWidth] = useState([0, 0]); // [width, height]

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth < 768) {
                // Bootstrap grid system 'sm' or less
                setWidth(window.innerWidth * 0.8);
            } else {
                setWidth(window.innerWidth * 0.5)
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <div id="cv" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>CV</h2>
                    <p>
                        Feel free to check out and download my CV.
                    </p>
                    <Document
                        options={{
                            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                            cMapPacked: true,
                        }}
                        file={CVPDF}
                        onLoadSuccess={console.log("CV viewed successfully")}
                        onLoadError={console.error}
                        externalLinkTarget="_blank"
                    >
                        <Page pageNumber={1} width={width}/>
                    </Document>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-4">
                        <a href={CVPDF} download="Terence_Hernandez_CV">
                            <button type="submit" className="btn btn-custom btn-lg">
                                Download
                            </button>
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <a href={CVPDF} target="_blank" name="CV">
                            <button type="submit" className="btn btn-custom btn-lg">
                                View PDF
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CV;
